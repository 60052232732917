<template>
  <v-layout class="patient-page" style="margin-bottom:60px;" justify-center>
    <v-flex xs12 sm10 md8 lg6>
      <div style="padding:10px;" v-html="rawHtml" /> </v-flex
  ></v-layout>
</template>

<script>
import CONSTS from '@/consts'

export default {
  data: () => ({
    rawHtml: ''
  }),
  methods: {
    async getHTML() {
      const resp = await fetch(CONSTS.privacyUrl)
      this.rawHtml = await resp.text()
    }
  },
  mounted() {
    this.getHTML()
  }
}
</script>

<style></style>
